.member {
	.page-main-ttl {
		background: url(#{$img}member/member-title.png) no-repeat;
		background-size: contain;
		position: absolute;
		top: -32px;
		left: 50%;
		transform: translateX(-50%);
		height: 67px;
		width: 610px;
		z-index: 10;
	}
	
	.wrap {
		margin: auto;
		padding: 0;
		width: 100%;
	}

	&-main {
		background-color: #fff;
		margin: 229px auto 0;
		padding: 160px 0 90px;
		position: relative;
		width: 1373px;
	}

	&-ceo-profile,
	&-list {
		margin: auto;
		padding: 105px 0 115px;
		position: relative;
		width: 1029px;

		&:before {
			content: "";
			border-top: 1px solid #000;
			position: absolute;
			top: -1px;
			left: 0;
			height: 0;
			width: 373px;
		}
	}

	&-ceo-profile {
		h3.member-item {
			background: url(#{$img}member/member-name.png) no-repeat;
			background-size: contain;
			padding: 0 0 80px;
			height: 92px;
			width: 364px;
		}
	}

	&-list {
		padding: 105px 0 0;
	}

	&-item-inner {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&-item {
		font-size: 0.875rem;
		line-height: 2.57;
		width: 480px;

		dl {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		dt {
			width: 175px;
		}
	}

	&-book {
		font-size: 0.875rem;
		line-height: 2.57;
		padding: 35px 0 0;
		
		a {
			text-decoration: underline;
		}
	}

	a.member-item {
		display: block;
		text-align: right;
		width: 154px;
	}
}

p.member-item {
	text-align: justify;
	text-justify: inter-ideograph;
}


/*===============================================
 responsive
===============================================*/
@media #{$sp} {
	.member {
		.page-main-ttl {
			top: 142px;
			width: 610px;
			height: 67px;
		}
	
		.wrap {
			margin: auto;
			width: 610px;
			padding: 315px 70px 74px;
		}

		&-main {
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}
	
		&-ceo-profile,
		&-list {
			padding: 110px 0 0;
			position: relative;
			width: 100%;
	
			&:before {
				top: -1px;
				left: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 243px;
			}
		}
	
		&-ceo-profile {
			h3.member-item {
				background: url(#{$img}member/member-name-sp.png) no-repeat;
				background-size: contain;
				padding: 0 0 60px;
				width: 610px;
				height: 135px;
			}

			p.member-item {
				padding: 80px 0 0;
			}
		}

		&-list {
			padding: 152px 0 0;
		}

		&-item {
			font-size: 22px;
			line-height: 2.18;
			width: 100%;

			dt {
				width: 240px;
			}

			dd {
				width: 370px;
			}
		}

		&-book {
			padding: 120px 0 150px;
			font-size: 22px;
			line-height: 2.18;
		}

		a.member-item {
			margin: auto;
			width: 199px;
		}
	}
}
