.event {
	padding: 230px 0 0 0;
	
	@media #{$sp} {
		padding: 0;
	}
	
	&-main {
		position: relative;
		max-width: 1373px;
		padding: 98px 0 220px;
		margin: 0 auto;
		background-color: #fff;
		
		@media #{$sp} {
			padding: 115px 0 110px;
		}
	}
	.page-main {
		&-ttl {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX( -50% ) translateY( -50% );
			width: 290px;
			height: 67px;
			background-image: url(#{$img}event/event-ttl.png);
			
			@media #{$sp} {
				position: relative;
				left: 0;
				z-index: 1000;
				transform: none;
				margin: 0 auto;
			}
		}
		&-catch {
			text-align: center;
			font-size: 18px;
			
			@media #{$sp} {
				margin: 44px 0 0 0;
				font-size: 26px;
				line-height: #{(40 / 26)};
			}
		}
	}
	&-search {
		display: flex;
		justify-content: space-between;
		width: 615px;
		margin: 94px auto 45px;
		
		@media #{$sp} {
			width: 610px;
			margin: 50px auto 45px;
		}
		
		&-col {
			width: 190px;
			
			.select-wrap {
				position: relative;
				display: block;
				width: 188px;
				height: 48px;
				border: 1px solid #000;
				
				&:after {
					position: absolute;
					right: 18px;
					top: -18px;
					width: 10px;
					height: 49px;
					background: url(#{$img}event/arw-select.png) no-repeat;
					pointer-events: none;
					content: '';
				}
				span {
					display: block;
					width: 100%;
					height: 100%;
					padding: 1em 0.75em;
					border: 0;
					box-sizing: border-box;
					font-size: 16px;
					line-height: 1;
					
					@media #{$sp} {
						padding: 0.625em 0.75em;
						font-size: 20px;
					}
				}
				select {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					appearance: none;
					opacity: 0;
				}
			}
		}
	}
	&-tags {
		margin: 0 0 64px 0;
		
		@media #{$sp} {
			margin: 0 70px 64px 70px;
		}
		
		p {
			margin: 0 0 2em 0;
			text-align: center;
			font-size: 16px;
			font-weight: bold;
			
			@media #{$sp} {
				margin: 0 0 1.5em 0;
				font-size: 26px;
			}
		}
		
		ul {
			text-align: center;
			font-size: 14px;
			
			@media #{$sp} {
				font-size: 24px;
				line-height: #{(36 / 24)};
			}
			
			li {
				display: inline-block;
				
				a {
					display: inline-block;
					padding: 0.25em 0.6em;
					border-radius: 0.8em;
					cursor: pointer;
					
					&:hover {
						opacity: 0.75;
					}
					&.active {
						background-color: #aa9653;
					}
				}
			}
		}
	}
	.contents-pager {
		margin: 64px 0 0 0;
		text-align: center;
		font-size: 0;
		
		@media #{$sp} {
			margin: 86px 70px 0;
		}
		
		a {
			display: inline-block;
			width: 50px;
			padding: 24px 0;
			margin: 0 8px;
			border: 1px solid #000;
			text-align: center;
			font-size: 18px;
			line-height: 1;
			cursor: pointer;
			
			@media #{$sp} {
				width: 72px;
				padding: 32px 0;
				margin: 0 10px;
				font-size: 27px;
			}
			
			&:hover {
				opacity: 0.75;
			}
			
			&.current {
				border: 1px solid #aa9653;
				background-color: #aa9653;
				pointer-events: none;
				cursor: default;
				
				&:hover {
					opacity: 1;
				}
			}
		}
	}
}