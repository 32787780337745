@charset "utf-8";

//-----------------------------------------------
// hover
//-----------------------------------------------
@mixin hover( $opacity:.7 ) {
	$ie: $opacity * 100;
	
	transition: opacity .2s linear;
	opacity: 1;
	
	&:hover {
		opacity: $opacity;
		-ms-filter: "alpha(opacity=#{$ie})";
	}
}

//-----------------------------------------------
// clearfix
//-----------------------------------------------
@mixin clearfix() {
	*zoom: 1;
	
	&:after {
		content: "";
		display: block;
		clear: both;
	}
}
