.page {
	padding: 156px 0 0 0;
	
	@media #{$sp} {
		padding: 148px 0 0 0;
	}
	
	&-ttl {
		text-align: center;
		font-size: 28px;
	}
	
	&-main {
		position: relative;
		max-width: 1373px;
		padding: 64px 0 228px;
		margin: 64px auto 0;
		background-color: #fff;
		
		@media #{$sp} {
			padding: 48px 70px 160px;
			margin: 48px 0 0 0;
		}
		
		.wrap {
			width: 1028px;
			margin: 0 auto;
			
			@media #{$sp} {
				width: auto;
				margin: 0;
			}
		}
	}
}