.membersclub {
	.page-main-ttl {
		background: url(#{$img}membersclub/membersclub-title.png) no-repeat;
		background-size: contain;
		position: absolute;
		top: -32px;
		left: 50%;
		transform: translateX(-50%);
		height: 147px;
		width: 610px;
		z-index: 10;
	}

	&-main {
		background-color: #fff;
		margin: 229px auto 0;
		padding: 210px 0 90px;
		position: relative;
		width: 1373px;
	}

	&-grid {
		box-sizing: border-box;
		display:-ms-grid;
		display: grid;
		font-size: 0.75rem;
		-ms-grid-colums: 75px 238px 238px 238px 239px;
		grid-template-columns: 75px 238px 238px 238px 239px;
		-ms-grid-rows: 215px 126px auto auto;
		grid-template-rows: 215px 126px auto auto;
		line-height: 1.5;
		margin: auto;
		padding: 0 0 75px;
		width: 1029px;
	}

	&-feature-ttl,
	&-benefits-ttl,
	&-fee-ttl,
	&-feature-ttl-2,
	&-benefits-ttl-2,
	&-fee-ttl-2,
	&-feature-ttl-3,
	&-benefits-ttl-3,
	&-fee-ttl-3,
	&-feature-ttl-4,
	&-benefits-ttl-4,
	&-fee-ttl-4 {
		background-color: #777059;
		border-top: 1px solid #898989;
		border-left: 1px solid #898989;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-feature-ttl-2,
	&-benefits-ttl-2,
	&-fee-ttl-2,
	&-feature-ttl-3,
	&-benefits-ttl-3,
	&-fee-ttl-3,
	&-feature-ttl-4,
	&-benefits-ttl-4,
	&-fee-ttl-4 {
		display: none;
	}

	&-feature-ttl {
		-ms-grid-column: 1;
		grid-column: 1/2;
		-ms-grid-row: 2;
		grid-row: 2/3;
	}

	&-benefits-ttl {
		-ms-grid-column: 1;
		grid-column: 1/2;
		-ms-grid-row: 3;
		grid-row: 3/4;
	}

	&-fee-ttl {
		border-bottom: 1px solid #898989;
		-ms-grid-column: 1;
		grid-column: 1/2;
		-ms-grid-row: 4;
		grid-row: 4/5;
	}

	&-black {
		-ms-grid-column: 2;
		grid-column: 2/3;
		-ms-grid-row: 1;
		grid-row: 1/2;
	}

	&-feature-black {
		-ms-grid-column: 2;
		grid-column: 2/3;
		-ms-grid-row: 2;
		grid-row: 2/3;
	}

	&-benefits-black {
		-ms-grid-column: 2;
		grid-column: 2/3;
		-ms-grid-row: 3;
		grid-row: 3/4;
	}

	&-fee-black {
		-ms-grid-column: 2;
		grid-column: 2/3;
		-ms-grid-row: 4;
		grid-row: 4/5;
	}

	&-gold {
		-ms-grid-column: 3;
		grid-column: 3/4;
		-ms-grid-row: 1;
		grid-row: 1/2;
	}

	&-feature-gold {
		-ms-grid-column: 3;
		grid-column: 3/4;
		-ms-grid-row: 2;
		grid-row: 2/3;
	}

	&-benefits-gold {
		-ms-grid-column: 3;
		grid-column: 3/4;
		-ms-grid-row: 3;
		grid-row: 3/4;
	}

	&-fee-gold {
		-ms-grid-column: 3;
		grid-column: 3/4;
		-ms-grid-row: 4;
		grid-row: 4/5;
	}

	&-silver {
		-ms-grid-column: 4;
		grid-column: 4/5;
		-ms-grid-row: 1;
		grid-row: 1/2;
	}

	&-feature-silver {
		-ms-grid-column: 4;
		grid-column: 4/5;
		-ms-grid-row: 2;
		grid-row: 2/3;
	}

	&-benefits-silver {
		-ms-grid-column: 4;
		grid-column: 4/5;
		-ms-grid-row: 3;
		grid-row: 3/4;
	}

	&-fee-silver {
		-ms-grid-column: 4;
		grid-column: 4/5;
		-ms-grid-row: 4;
		grid-row: 4/5;
	}

	&-bronze {
		-ms-grid-column: 5;
		grid-column: 5/6;
		-ms-grid-row: 1;
		grid-row: 1/2;
	}

	&-feature-bronze {
		-ms-grid-column: 5;
		grid-column: 5/6;
		-ms-grid-row: 2;
		grid-row: 2/3;
	}

	&-benefits-bronze {
		-ms-grid-column: 5;
		grid-column: 5/6;
		-ms-grid-row: 3;
		grid-row: 3/4;
	}

	&-fee-bronze {
		-ms-grid-column: 5;
		grid-column: 5/6;
		-ms-grid-row: 4;
		grid-row: 4/5;

		small {
			display: block;
			font-size: 0.875rem;
		}

		span.membersclub-note {
			display: block;
			font-size: 0.75rem;
		}
	}

	&-benefits-black,
	&-benefits-gold,
	&-benefits-silver,
	&-benefits-bronze,
	&-fee-black,
	&-fee-gold,
	&-fee-silver,
	&-fee-bronze {
		background-color: #e7e5e5;
		border-top: 1px solid #898989;
		border-left: 1px solid #898989;
		padding: 24px;
	}

	&-fee-black,
	&-fee-gold,
	&-fee-silver,
	&-fee-bronze {
		background-color: #fff;
		border-bottom: 1px solid #898989;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.125rem;
		padding: 24px;
		text-align: center;
	}

	&-benefits-bronze,
	&-fee-bronze {
		border-right: 1px solid #898989;
	}

	&-contact-btn {
		display: block;
		margin: auto;
		width: 380px;
	}

}


/*===============================================
 responsive
===============================================*/
@media #{$sp} {
	.membersclub {
		.page-main-ttl {
			top: 142px;
			width: 610px;
			height: 147px;
		}

		&-main {
			margin: 0;
			width: 610px;
			padding: 350px 70px 74px;
		}

		&-grid {
			font-size: 24px;
			grid-template-columns: 1fr;
			grid-template-rows: 215px 70px 172px 70px auto 70px auto
								215px 70px 172px 70px auto 70px auto
								215px 70px 172px 70px auto 70px auto
								215px 70px 172px 70px auto 70px auto;
			padding: 0;
			width: 100%;
		}

		&-feature-ttl,
		&-benefits-ttl,
		&-fee-ttl,
		&-feature-ttl-2,
		&-benefits-ttl-2,
		&-fee-ttl-2,
		&-feature-ttl-3,
		&-benefits-ttl-3,
		&-fee-ttl-3,
		&-feature-ttl-4,
		&-benefits-ttl-4,
		&-fee-ttl-4,
		&-black,
		&-feature-black,
		&-benefits-black,
		&-fee-black,
		&-gold,
		&-feature-gold,
		&-benefits-gold,
		&-fee-gold,
		&-silver,
		&-feature-silver,
		&-benefits-silver,
		&-fee-silver,
		&-bronze,
		&-feature-bronze,
		&-benefits-bronze,
		&-fee-bronze {
			border: none;
			grid-column: 1/2;
		}

		&-feature-ttl,
		&-benefits-ttl,
		&-fee-ttl,
		&-feature-ttl-2,
		&-benefits-ttl-2,
		&-fee-ttl-2,
		&-feature-ttl-3,
		&-benefits-ttl-3,
		&-fee-ttl-3,
		&-feature-ttl-4,
		&-benefits-ttl-4,
		&-fee-ttl-4 {
			border: 1px solid #898989;
			display: flex;
			font-size: 30px;
			height: 69px;
		}

		&-feature-ttl {
			grid-row: 2/3;
		}

		&-benefits-ttl {
			grid-row: 4/5;
		}

		&-fee-ttl {
			grid-row: 6/7;
		}

		&-feature-ttl-2 {
			grid-row: 9/10;
		}

		&-benefits-ttl-2 {
			grid-row: 11/12;
		}

		&-fee-ttl-2 {
			grid-row: 13/14;
		}

		&-feature-ttl-3 {
			grid-row: 16/17;
		}

		&-benefits-ttl-3 {
			grid-row: 18/19;
		}

		&-fee-ttl-3 {
			grid-row: 20/21;
		}

		&-feature-ttl-4 {
			grid-row: 23/24;
		}

		&-benefits-ttl-4 {
			grid-row: 25/26;
		}

		&-fee-ttl-4 {
			grid-row: 27/28;
		}
	
		&-black {
			grid-row: 1/2;
		}
	
		&-feature-black {
			grid-row: 3/4;
		}
	
		&-benefits-black {
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 5/6;
		}
	
		&-fee-black {
			border-bottom: 1px solid #898989; 
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 7/8;
			margin: 0 0 70px;
		}
	
		&-gold {
			grid-row: 8/9;
		}
	
		&-feature-gold {
			grid-row: 10/11;
		}
	
		&-benefits-gold {
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 12/13;
		}
	
		&-fee-gold {
			border-bottom: 1px solid #898989; 
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 14/15;
			margin: 0 0 70px;
		}
	
		&-silver {
			grid-row: 15/16;
		}
	
		&-feature-silver {
			grid-row: 17/18;
		}
	
		&-benefits-silver {
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 19/20;
		}
	
		&-fee-silver {
			border-bottom: 1px solid #898989; 
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 21/22;
			margin: 0 0 70px;
		}
	
		&-bronze {
			grid-row: 22/23;
		}
	
		&-feature-bronze {
			grid-row: 24/25;
		}
	
		&-benefits-bronze {
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 26/27;
		}
	
		&-fee-bronze {
			border-bottom: 1px solid #898989; 
			border-left: 1px solid #898989;
			border-right: 1px solid #898989;
			grid-row: 28/29;
			margin: 0 0 96px;
	
			small {
				font-size: 30px;
			}
	
			span.membersclub-note {
				font-size: 24px;
			}
		}
	
		&-benefits-black,
		&-benefits-gold,
		&-benefits-silver,
		&-benefits-bronze,
		&-fee-black,
		&-fee-gold,
		&-fee-silver,
		&-fee-bronze {
			padding: 35px;
		}
	
		&-fee-black,
		&-fee-gold,
		&-fee-silver,
		&-fee-bronze {
			font-size: 30px;
		}

		&-contact-btn {
			width: 100%;
			max-width: 574px;
		}
	}
}