.notfound-404 {
	padding: 320px 0;
	
	p {
		text-align: center;
		font-size: 24px;
		
		@media #{$sp} {
			font-size: 36px;
		}
	}
}