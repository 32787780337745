.contents-mabo {
	.contents-cat {
		&-header {
			background-image: url(#{$img}contents/mabo/mv.jpg);
			
			@media #{$sp} {
				background-image: url(#{$img}contents/mabo/mv-s.jpg);
			}
		}
		&-ttl {
			width: 160px;
			height: 61px;
			background-image: url(#{$img}contents/mabo/ttl.png);
			
			@media #{$sp} {
				width: 240px;
				height: 91px;
				background-image: url(#{$img}contents/mabo/ttl-s.png);
			}
		}
		&-about {
			&-ttl {
				width: 350px;
				height: 58px;
				background-image: url(#{$img}contents/mabo/about-ttl.png);
				
				@media #{$sp} {
					width: 428px;
					height: 73px;
					background-image: url(#{$img}contents/mabo/about-ttl-s.png);
				}
			}
		}
	}
	&-about {
		&-1 {
			p {
				text-align: center;
				font-size: 16px;
				line-height: #{(36 / 16)};
				
				@media #{$sp} {
					text-align: left;
					font-size: 26px;
					line-height: #{(48 / 26)};
				}
			}
		}
		&-pic {
			margin: 52px 0 0 0;
			
			@media #{$sp} {
				margin: 64px -70px 0;
			}
			
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 64px 0 0 0;
			
			@media #{$sp} {
				flex-direction: column;
				margin: 72px 0 0 0;
			}
		}
		&-left,
		&-right {
			width: 436px;
			
			@media #{$sp} {
				width: auto;
			}
		}
		&-right {
			@media #{$sp} {
				margin: 118px 0 0 0;
			}
		}
		&-2,
		&-3,
		&-4 {
			h3 {
				text-align: center;
				font-size: 18px;
				
				@media #{$sp} {
					font-size: 48px;
				}
			}
			p {
				margin: 28px 0 0 0;
				font-size: 14px;
				line-height: #{(24 / 14)};
				
				@media #{$sp} {
					margin: 64px 0 0 0;
					font-size: 26px;
					line-height: #{(48 / 26)};
				}
			}
			dl {
				margin: 32px 0 0 0;
				
				@media #{$sp} {
					margin: 48px 0 0 0;
				}
				
				&:first-child {
					margin: 0;
				}
				dt {
					font-size: 18px;
					
					@media #{$sp} {
						font-size: 36px;
						font-weight: bold;
					}
				}
				dd {
					margin: 8px 0 0 0;
					font-size: 14px;
					line-height: #{(24 / 14)};
					
					@media #{$sp} {
						font-size: 26px;
						line-height: #{(48 / 26)};
					}
				}
			}
			table {
				font-size: 14px;
				line-height: #{(24 / 14)};
					
				@media #{$sp} {
					font-size: 26px;
					line-height: #{(48 / 26)};
				}
				
				tbody {
					th {
						text-align: left;
						font-weight: normal;
						
						@media #{$sp} {
							display: block;
						}
					}
					td {
						padding: 0 0 0 2em;
						text-align: left;
						
						@media #{$sp} {
							display: inline;
							padding: 0;
							
							&:nth-child(3) {
								padding: 0 0 0 1em;
							}
						}
					}
				}
			}
		}
		&-4 {
			margin: 72px 0 0 0;
			
			@media #{$sp} {
				margin: 118px 0 0 0;
			}
		}
	}
}