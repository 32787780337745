@charset "utf-8";

//-----------------------------------------------
// responsive setting
//-----------------------------------------------
// $sp: "only screen and (max-width: 979px)";
$pc: "only screen and (min-width: 751px)";
$sp: "only screen and (max-width: 750px)";

/*===============================================
 responsive element
===============================================*/
.pc-inline {
	display: inline !important;
}
.pc-inline-block {
	display: inline-block !important;
}
.pc-block {
	display: block !important;
}
.sp-inline,
.sp-inline-block,
.sp-block {
	display: none !important;
}
#IsSp {
	display: none;
}

@media #{$sp} {
	.pc-inline,
	.pc-inline-block,
	.pc-block {
		display: none !important;
	}
	.sp-inline {
		display: inline !important;
	}
	.sp-inline-block {
		display: inline-block !important;
	}
	.sp-block {
		display: block !important;
	}
	#IsSp {
		display: block;
	}
}

//-----------------------------------------------
// spvw
//-----------------------------------------------
@function spvw( $size ) {
	@return #{(100 / $sp-base * $size)}vw;
}
