body#contents-q-project {
	background-color: #000000;
	
	.header {
		background-color: #5b6ca6;
		
		&:after {
			background-image: url(#{$img}contents/q-project/header-over.png);
		}
		@media #{$sp} {
			&:before {
				background-image: url(#{$img}contents/q-project/header-over-s.png);
			}
		}
		&-logo {
			a {
				background-image: url(#{$img}contents/q-project/header-logo.png);
			}
		}
		&-menu {
			background-color: #5b6ca6;
			
			.border {
				span {
					background-color: #fff;
				}
			}
		}
	}
	.footer {
		background-image: url(#{$img}contents/q-project/footer-bg.png);
		
		&:after {
			background-image: url(#{$img}contents/q-project/footer-over.png);
			
			@media #{$sp} {
				background-image: url(#{$img}contents/q-project/footer-over-s.png);
			}
		}
		&-inner {
			background-color: #5b6ca6;
		}
		&-logo {
			background-image: url(#{$img}contents/q-project/footer-logo.png);
		}
		&-copyright {
			background-image: url(#{$img}contents/q-project/footer-copy.png);
			
			@media #{$sp} {
				background-image: url(#{$img}contents/q-project/footer-copy-s.png);
			}
		}
		&-catch {
			background-image: url(#{$img}contents/q-project/footer-catch.png);
			
			@media #{$sp} {
				background-image: url(#{$img}contents/q-project/footer-catch-s.png);
			}
		}
		&-sns {
			a {
				&:nth-child(1) {
					background-image: url(#{$img}contents/q-project/footer-sns-fb.png);
			
					@media #{$sp} {
						background-image: url(#{$img}contents/q-project/footer-sns-fb-s.png);
					}
				}
				&:nth-child(2) {
					background-image: url(#{$img}contents/q-project/footer-sns-tw.png);
			
					@media #{$sp} {
						background-image: url(#{$img}contents/q-project/footer-sns-tw-s.png);
					}
				}
			}
		}
		&-pagetop {
			a {
				background-image: url(#{$img}contents/q-project/pagetop.png);
			
				@media #{$sp} {
					background-image: url(#{$img}contents/q-project/pagetop-s.png);
				}
			}
		}
	}
	
}
.contents-q-project {
	padding: 336px 0 360px;
	
	@media #{$sp} {
		padding: 410px 0 320px;
	}
	
	&-logo {
		width: 209px;
		margin: 0 auto;
		cursor: pointer;
		
		&:hover {
			opacity: 0.75;
		}
	}
	&-secret {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 100000;
		width: 100%;
		height: 100%;
		background-color: rgba( 0,0,0,0.75 );
		opacity: 0;
		pointer-events: none;
		transform: scale( 1.1 );
		transition: opacity .4s linear, transform .4s ease-out;
		
		&-inner {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX( -50% ) translateY( -50% );
		}
		&-number {
			display: block;
			width: 378px;
			height: 17px;
			background: url(#{$img}contents/q-project/secret-number.png) no-repeat;
		}
	}
}
body.secret-visible .contents-q-project-secret {
	opacity: 1;
	transform: scale( 1 );
	pointer-events: auto;
	cursor: pointer;
}