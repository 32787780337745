.home {
	&-mv {
		position: relative;
		background-color: #aa9653;

		&-inner {
			width: #{$pc-center}px;
			height: 750px;
			margin: 0 auto;
			
			@media #{$sp} {
				width: 100%;
				height: 766px;
				// height: spvw( 766 );
			}
			&:after {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				bottom: -128px;
				background: url(#{$img}home/mv-border.png) repeat-y center top;
				pointer-events: none;
				content: '';
				
				@media #{$sp} {
					display: none;
				}
			}
		}
		&-video {
			position: relative;
			width: 1374px;
			height: 878px;
			margin: 0 percentage( ( $pc-center - 1374 ) / 2 / $pc-center );
			
			@media #{$sp} {
				// width: spvw( 636 );
				// height: spvw( 982 );
				width: 636px;
				height: 982px;
				margin: 0 auto;
			}
			
			&-container {
				position: relative;
				width: 100%;
				height: 100%;
				background: url(#{$img}home/mv.jpg) no-repeat center center;
				background-size: cover;
				
				iframe {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					
					display: none;
					
					@media #{$sp} {
						display: block;
						opacity: 0;
					}
				}
			}
			&-play {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate( -50%, -50% );
				width: 146px;
				height: 146px;
				background: url(#{$img}home/mv-video-play.png) no-repeat;
				cursor: pointer;
				
				@media #{$sp} {
					pointer-events: none;
				}
			}
		}
		&-right {
			position: absolute;
			right: 0;
			top: 0;
			width: 144px;
			height: 540px;
			background-color: #aa9653;
			
			@media #{$sp} {
				// top: spvw( 982 );
				// width: 100%;
				// height: spvw( 933 );
				top: 982px;
				width: 100%;
				height: 933px;
				background-color: transparent;
			}
			
			&-ttl {
				width: 52px;
				height: 451px;
				margin: 0 0 0 32px;
				background: url(#{$img}home/mv-ttl.png) no-repeat;
				
				@media #{$sp} {
					width: 100%;
					height: 100%;
					margin: 0;
					background-image: url(#{$img}home/mv-ttl-s.png);
					background-size: contain;
				}
			}
			
		}
		&-modal {
			position: fixed;
			left: 0;
			top: 0;
			z-index: 99999;
			width: 100%;
			height: 100%;
			opacity: 0;
			pointer-events: none;
			transition: opacity .5s linear;
			
			&.visible {
				opacity: 1;
				pointer-events: auto;
			}
			
			&-overlay {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #000;
				opacity: 0.8;
			}
			&-player {
				position: absolute;
				left: 50px;
				right: 50px;
				top: 50px;
				bottom: 50px;
				background-color: #000;
				
				iframe {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
				}
			}
			&-close {
				position: absolute;
				right: 25px;
				top: 25px;
				width: 50px;
				height: 50px;
				border-radius: 25px;
				background-color: #fff;
				cursor: pointer;
				
				&:before,
				&:after {
					position: absolute;
					left: 50%;
					top: 50%;
					width: 30px;
					height: 1px;
					background-color: #000;
					content: '';
				}
				&:before {
					transform: translateX(-50%) translateY(-50%) rotate(45deg);
				}
				&:after {
					transform: translateX(-50%) translateY(-50%) rotate(-45deg);
				}
			}
		}
	}
	&-main {
		position: relative;

		&-inner {
			position: relative;
			padding: 212px 0 598px;
			background: url(#{$img}home/main-bg.jpg) no-repeat center bottom;
			
			@media #{$sp} {
				// width: spvw( 697 );
				// margin: spvw( 1228 ) auto 0;
				// padding: spvw( 8 ) spvw( 8 ) spvw( 942 );
				width: 697px;
				margin: 1228px auto 0;
				padding: 8px 8px 941px;
				background-image: url(#{$img}home/main-bg-s.jpg);
				background-size: 100% auto;
				background-color: #fff;
				
				&:after {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					// border: spvw( 8 ) solid #000;
					border: 8px solid #000;
					box-sizing: border-box;
					pointer-events: none;
					content: '';
				}
			}
			
			// 
			.spoonRect {
				position: absolute;
				left: 50%;
				bottom: 460px;
				width: 420px;
				height: 340px;
				margin-left: -528px;
				
				@media #{$sp} {
					// bottom: spvw( 500 );
					// width: spvw( 415 );
					// height: spvw( 340 );
					// margin-left: spvw( -320 );
					bottom: 500px;
					width: 415px;
					height: 340px;
					margin-left: -320px;
				}
			}
		}
		&:before {
			position: absolute;
			left: 50%;
			bottom: -143px;
			width: 1273px;
			height: 143px;
			margin: 0 0 0 -636.5px;
			background: url(#{$img}home/main-bottom.png) no-repeat;
			pointer-events: none;
			content: '';
			
			@media #{$sp} {
				left: 0;
				bottom: -137px;
				width: 100%;
				height: 138px;
				margin: 0;
				background-image: url(#{$img}home/main-bottom-s.png);
			}
		}
		&:after {
			position: absolute;
			left: 50%;
			top: 128px;
			bottom: 0;
			width: 1273px;
			height: auto;
			margin: 0 0 0 -636.5px;
			border-left: 10px solid #3f340e;
			border-right: 10px solid #3f340e;
			box-sizing: border-box;
			pointer-events: none;
			content: '';
			
			@media #{$sp} {
				display: none;
			}
		}
		&-about {
			position: relative;
			width: 1373px;
			height: 1015px;
			padding: 45px 0 0 0;
			margin: 0 auto;
			box-sizing: border-box;
			background: url(#{$img}home/about-bg.png) no-repeat;
			
			@media #{$sp} {
				width: auto;
				// height: spvw( 963 );
				// padding: spvw( 76 ) 0 0 0;
				// margin: 0 spvw( -25 );
				height: 963px;
				padding: 76px 0 0 0;
				margin: 0 -25px;
				background-image: url(#{$img}home/about-bg-s.jpg);
				background-size: cover;
			}
			&-ttl {
				position: relative;
				width: 991px;
				height: 266px;
				margin: 0 auto;
				background: url(#{$img}home/about-ttl.png) no-repeat;
				
				@media #{$sp} {
					// width: spvw( 670 );
					// height: spvw( 283 );
					width: 670px;
					height: 283px;
					background-image: url(#{$img}home/about-ttl-s.png);
					background-size: contain;
				}
				
				// 
				.wall {
					position: absolute;
					left: 0;
					top: 17px;
					width: 100%;
					height: 71px;
					
					@media #{$sp} {
						// height: spvw( 120 );
						top: 68px;
						height: 62px;
						width: 94%;
					}
				}
			}
			&-catch {
				width: 432px;
				height: 68px;
				margin: -128px auto 0;
				background: url(#{$img}home/about-catch.png) no-repeat;
				
				@media #{$sp} {
					// width: spvw( 493 );
					// height: spvw( 82 );
					width: 493px;
					height: 82px;
					margin: 0 auto;
					background-image: url(#{$img}home/about-catch-s.png);
					background-size: contain;
				}
			}
			&-txt {
				width: 548px;
				height: 109px;
				margin: 32px auto 0;
				background: url(#{$img}home/about-txt.png) no-repeat;
				
				@media #{$sp} {
					// width: spvw( 523 );
					// height: spvw( 176 );
					// margin: spvw( 44 ) auto 0;
					width: 523px;
					height: 176px;
					margin: 44px auto 0;
					background-image: url(#{$img}home/about-txt-s.png);
					background-size: contain;
				}
			}
			@media #{$sp} {
				&:after {
					position: absolute;
					left: 0;
					// bottom: spvw( -150 );
					bottom: -150px;
					z-index: 1;
					width: 100%;
					// height: spvw( 409 );
					height: 409px;
					background: url(#{$img}home/about-bg-over-s.png) no-repeat;
					background-size: contain;
					content: '';
				}
			}
			//
			.dishRect {
				position: absolute;
				left: 270px;
				right: 260px;
				top: 480px;
				bottom: 80px;
				
				@media #{$sp} {
					// left: spvw( 110 );
					// right: spvw( 110 );
					// top: spvw( 720 );
					// bottom: spvw( 0 );
					left: 110px;
					right: 110px;
					top: 720px;
					bottom: 0;
				}
			}
		}
		&-components {
			width: 1044px;
			margin: 16px auto 0;
			
			@media #{$sp} {
				// width: spvw( 650 );
				// margin: spvw( 128 ) auto 0;
				width: 650px;
				margin: 128px auto 0;
			}
			
			&-ttl {
				width: 722px;
				height: 53px;
				margin: 0 auto;
				background: url(#{$img}home/components-ttl.png) no-repeat;
				
				@media #{$sp} {
					// width: spvw( 610 );
					// height: spvw( 45 );
					width: 610px;
					height: 45px;
					background-image: url(#{$img}home/components-ttl-s.png);
					background-size: contain;
				}
			}
			&-catch {
				width: 574px;
				height: 25px;
				margin: 32px auto 0;
				background: url(#{$img}home/components-catch.png) no-repeat;
				
				@media #{$sp} {
					// width: spvw( 574 );
					// height: spvw( 25 );
					// margin: spvw( 16 ) auto 0;
					width: 574px;
					height: 25px;
					margin: 16px auto 0;
					background-image: url(#{$img}home/components-catch-s.png);
					background-size: contain;
				}
			}
			&-inner {
				display: flex;
				justify-content: space-between;
				margin: 36px 0 0 0;
				
				@media #{$sp} {
					flex-wrap: wrap;
					justify-content: flex-start;
					// margin: spvw( 50 ) 0 0 0;
					margin: 50px 0 0 0;
				}
			}
			&-item {
				width: 257px;
				height: 293px;
				background-repeat: no-repeat;
				background-position: center center;
				
				@media #{$sp} {
					// width: spvw( 320 );
					// height: spvw( 360 );
					// margin: spvw( 8 ) 0 0 spvw( 8 );
					width: 320px;
					height: 360px;
					margin: 8px 0 0 8px;
					background-size: cover;
					
					&:nth-child(-n+2) {
						margin-top: 0;
					}
					&:nth-child(2n+1) {
						margin-left: 0;
					}
				}

				&:nth-child(1) {
					background-image: url(#{$img}home/components-item-food.jpg);
					
					@media #{$sp} {
						background-image: url(#{$img}home/components-item-food-s.jpg);
					}
				}
				&:nth-child(2) {
					background-image: url(#{$img}home/components-item-restaurant.jpg);
					
					@media #{$sp} {
						background-image: url(#{$img}home/components-item-restaurant-s.jpg);
					}
				}
				&:nth-child(3) {
					background-image: url(#{$img}home/components-item-event.jpg);
					
					@media #{$sp} {
						background-image: url(#{$img}home/components-item-event-s.jpg);
					}
				}
				&:nth-child(4) {
					background-image: url(#{$img}home/components-item-content.jpg);
					
					@media #{$sp} {
						background-image: url(#{$img}home/components-item-content-s.jpg);
					}
				}
			}
			&-link {
				margin: 45px 0 0 0;

				a {
					display: block;
					width: 93px;
					height: 13px;
					margin: 0 auto;
					background: url(#{$img}home/link-more.png) no-repeat;
					
					@media #{$sp} {
						// width: spvw( 184 );
						// height: spvw( 26 );
						width: 184px;
						height: 26px;
						background-image: url(#{$img}home/link-more-s.png);
						background-size: contain;
					}
				}
			}
		}
		&-news {
			position: relative;
			width: 1028px;
			margin: 82px auto 0;
			
			@media #{$sp} {
				// width: spvw( 650 );
				// margin: spvw( 140 ) auto 0;
				width: 650px;
				margin: 140px auto 0;
			}
			
			&-ttl {
				position: absolute;
				left: 20px;
				top: 0px;
				width: 226px;
				height: 161px;
				background: url(#{$img}home/news-ttl.png) no-repeat;
				
				@media #{$sp} {
					position: relative;
					left: 0;
					margin: 0;
					// width: spvw( 272 );
					// height: spvw( 194 );
					width: 272px;
					height: 194px;
					background-image: url(#{$img}home/news-ttl-s.png);
					background-size: contain;
				}
				
				// 
				.wall {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 40px;
					
					@media #{$sp} {
						// height: spvw( 85 );
						height: 85px;
					}
				}
			}
			&-inner {
				width: 718px;
				border-top: 1px solid #9a9a9a;
				margin: 0 20px 0 auto;
				
				@media #{$sp} {
					width: 100%;
					// margin: spvw( -40 ) 0 0 0;
					margin: -40px 0 0 0;
				}
			}
			&-item {
				border-bottom: 1px solid #9a9a9a;
				
				a {
					display: flex;
					
					@media #{$sp} {
						flex-direction: column;
					}
				}
				&-pubdate {
					padding: 28px 16px;
					font-size: 14px;
					line-height: #{(24 / 14)};
					
					@media #{$sp} {
						// padding: spvw( 14 ) spvw( 20 ) 0 spvw( 20 );
						// font-size: spvw( 26 );
						padding: 14px 20px 0 20px;
						font-size: 26px;
						line-height: #{(44/26)};
					}
				}
				&-ttl {
					padding: 28px 16px;
					font-size: 14px;
					line-height: #{(24 / 14)};
					
					@media #{$sp} {
						// padding: spvw( 14 ) spvw( 20 ) spvw( 14 ) spvw( 20 );
						// font-size: spvw( 26 );
						padding: 14px 20px 14px 20px;
						font-size: 26px;
						line-height: #{(44/26)};
					}
				}
			}
			/*
			&-link {
				position: absolute;
				right: 40px;
				top: 156px;

				a {
					display: block;
					width: 93px;
					height: 13px;
					background: url(#{$img}home/link-more.png) no-repeat;
				}
			}
			*/
		}
		&-event {
			position: relative;
			width: 1028px;
			margin: 100px auto 0;
			
			@media #{$sp} {
				// width: spvw( 650 );
				// margin: spvw( 140 ) auto 0;
				width: 650px;
				margin: 140px auto 0;
			}

			&-ttl {
				position: absolute;
				right: 40px;
				top: 66px;
				width: 251px;
				height: 161px;
				background: url(#{$img}home/event-ttl.png) no-repeat;
				
				@media #{$sp} {
					position: relative;
					right: auto;
					top: 0;
					// width: spvw( 251 );
					// height: spvw( 161 );
					width: 286px;
					height: 193px;
					background-image: url(#{$img}home/event-ttl-s.png);
				}

				// 
				.wall {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 40px;
				}
			}
			&-inner {
				display: flex;
				justify-content: space-around;
				
				@media #{$sp} {
					flex-direction: column;
					justify-content: flex-start;
				}
			}
			&-item {
				width: 294px;
				
				@media #{$sp} {
					// width: spvw( 528 );
					width: 528px;
					margin-left: auto;
					margin-right: auto;
				}

				a {
					display: block;
				}

				&:nth-child(2) {
					margin-top: 116px;
					
					@media #{$sp} {
						// margin-top: spvw( 60 );
						margin-top: 60px;
					}
				}
				&:nth-child(3) {
					margin-top: 232px;
					
					@media #{$sp} {
						// margin-top: spvw( 60 );
						margin-top: 60px;
					}
				}

				&-pic {
					width: 294px;
					height: 294px;
					border-radius: 50%;
					overflow: hidden;
					
					@media #{$sp} {
						// width: spvw( 460 );
						// height: spvw( 460 );
						width: 460px;
						height: 460px;
						margin: 0 auto;
					}

					img {
						display: block;
						width: 100%;
					}
				}
				&-pubdate {
					display: block;
					margin: 0.5em 0 0 0;
					font-size: 16px;
					font-style: italic;
					
					@media #{$sp} {
						font-size: 30px;
					}
				}
				&-ttl {
					margin: 0.5em 0 0 0;
					font-size: 14px;
					line-height: #{(24 / 14)};
					
					@media #{$sp} {
						font-size: 26px;
						line-height: #{(45 / 26)};
					}
				}
			}
			&-link {
				position: absolute;
				right: 40px;
				bottom: -60px;

				a {
					display: block;
					width: 93px;
					height: 13px;
					background: url(#{$img}home/link-more.png) no-repeat;
				}
			}
		}
	}
	&-contents {
		padding: 190px 0 0 0;
		margin: -132px 0 0 0;
		background: url(#{$img}home/contents-bg.png) repeat center top;
		
		@media #{$sp} {
			// padding: spvw( 476 ) 0 spvw( 40 ) 0;
			// margin: spvw( -312 ) 0 0 0;
			padding: 476px  0 40px 0;
			margin: -312px 0 0 0;
		}
		&-ttl {
			width: 163px;
			height: 29px;
			margin: 0 auto;
			background: url(#{$img}home/contents-ttl.png) no-repeat;
			
			@media #{$sp} {
				// width: spvw( 268 );
				// height: spvw( 45 );
				width: 242px;
				height: 45px;
				background-image: url(#{$img}home/contents-ttl-s.png);
				background-size: contain;
			}
		}
		&-catch {
			width: 393px;
			height: 20px;
			margin: 20px auto 0;
			background: url(#{$img}home/contents-catch.png) no-repeat;
			
			@media #{$sp} {
				// width: spvw( 589 );
				// height: spvw( 30 );
				// margin: spvw( 28 ) auto 0;
				width: 589px;
				height: 30px;
				margin: 28px auto 0;
				background-image: url(#{$img}home/contents-catch-s.png);
				background-size: contain;
			}
		}
		&-inner {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin: 58px 0 0 0;
			
			@media #{$sp} {
				flex-direction: column;
				width: auto;
				// margin: spvw( 38 ) spvw( 18 ) 0;
				margin: 38px 18px 0;
			}
		}
		&-item {
			width: percentage( 1 / 3 );
			
			@media #{$sp} {
				width: 100%;
			}
			a {
				position: relative;
				display: block;
				color: #fff;
				text-decoration: none;
				
				&[target="_blank"] {
					&:after {
						position: absolute;
						right: 16px;
						top: 16px;
						display: block;
						width: 24px;
						height: 24px;
						background: url(#{$img}common/ico-blank.png) no-repeat;
						background-size: contain;
						pointer-events: none;
						content: '';
						
						@media #{$sp} {
							right: 24px;
							top: 24px;
							width: 48px;
							height: 48px;
						}
					}
				}
			}
			&-pic {
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&-inner {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate( -50%, -50% );
				// width: percentage( 320 / 500 );
				width: 90%;
			}
			&-ttl {
				color: #fff;
				font-size: 25px;
				text-align: center;
				
				@media #{$sp} {
					// font-size: spvw( 35 );
					font-size: 40px;
				}
			}
			&-description {
				margin: 1em 0 0 0;
				text-align: center;
				font-size: 15px;
				line-height: #{(26 / 15)};
				
				@media #{$sp} {
					// font-size: spvw( 21 );
					font-size: 26px;
				}
			}
		}
	}
	&-kyoto {
		padding: 72px 0 48px;
		background: url(#{$img}home/kyoto-bg.png) repeat center -474px;
		
		@media #{$sp} {
			// padding: spvw( 60 ) 0 spvw( 130 );
			padding: 60px 0 130px;
		}
		
		&-ttl {
			width: 156px;
			height: 45px;
			margin: 0 auto;
			background: url(#{$img}home/kyoto-ttl.png) no-repeat;
			
			@media #{$sp} {
				// width: spvw( 219 );
				// height: spvw( 62 );
				width: 219px;
				height: 62px;
				background-image: url(#{$img}home/kyoto-ttl-s.png);
				background-size: contain;
			}
		}
		&-inner {
			display: flex;
			flex-wrap: wrap;
			width: 1028px;
			margin: 50px auto 0;
			
			@media #{$sp} {
				width: auto;
				// margin: spvw( 48 ) spvw( 18 ) 0;
				margin: 48px 18px 0;
			}
		}
		&-item {
			a {
				display: block;
				
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&:nth-child(-n+8) {
				width: 505px;
				margin: 18px 0 0 18px;
				
				@media #{$sp} {
					width: 100%;
					// margin: spvw( 12 ) 0 0 0;
					margin: 12px 0 0 0;
				}

				&:nth-child(2n+1) {
					margin-left: 0;
				}
				&:nth-child(-n+2) {
					margin-top: 0;
					
					@media #{$sp} {
						// margin-top: spvw( 12 );
						margin-top: 12px;
					}
				}
				@media #{$sp} {
					&:first-child {
						margin-top: 0;
					}
				}
			}
			&:nth-child(n+9) {
				width: 330px;
				margin: 18px 0 0 18px;
				
				@media #{$sp} {
					// width: spvw( 350 );
					// margin: spvw( 14 ) 0 0 spvw( 14 );
					width: 350px;
					margin: 14px 0 0 14px;
				}
				
				&:nth-child(3n) {
					margin-left: 0;
					
					@media #{$sp} {
						// margin-left: spvw( 14 );
						margin-left: 14px;
					}
				}
				@media #{$sp} {
					&:nth-child(2n+1) {
						margin-left: 0;
					}
				}
			}
		}
	}
}