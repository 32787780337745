.contents {
	&-detail {
		padding: 152px 0 0 0;
		
		@media #{$sp} {
			padding: 0;
		}
		
		&-main {
			max-width: 1375px;
			padding: 64px 0 220px;
			margin: 0 auto;
			background-color: #fff;
			
			@media #{$sp} {
				padding: 140px 0 120px;
			}
		}
		&-pubdate {
			display: block;
			text-align: center;
			
			span {
				display: inline-block;
				padding: 0 0 12px 0;
				border-bottom: 1px solid #000;
				font-size: 12px;
				font-style: italic;
				
				@media #{$sp} {
					font-size: 20px;
					padding: 0;
					border-bottom: 0;
				}
			}
		}
		&-ttl {
			position: relative;
			z-index: 1;
			width: 1028px;
			margin: 64px auto 0;
			text-align: center;
			font-size: 48px;
			font-weight: normal;
			font-style: italic;
			line-height: 1.2;
			
			@media #{$sp} {
				width: 610px;
				margin: 48px auto 0;
				font-size: 60px;
				line-height: #{(72 / 60)};
			}
			
			span {
				position: relative;
				padding: 0 0.1em;
				display: inline;
				background-color: #aa9552;
				
				&:after {
					position: absolute;
					right: 0;
					bottom: -85px;
					width: 160px;
					height: 85px;
					background: url(#{$img}contents/detail/ttl-bottom.png) no-repeat;
					content: '';
				}
			}
		}
		&-mv {
			position: relative;
			width: 100%;
			height: 508px;
			margin: 64px 0 0 0;
			overflow: hidden;
			
			@media #{$sp} {
				height: auto;
				margin: 40px 0 0 0;
			}
			
			&-bg {
				position: absolute;
				left: -7px;
				right: -7px;
				top: -7px;
				bottom: -7px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				filter: grayscale(100%) blur( 7px );
				
				@media #{$sp} {
					display: none;
				}
			}
			&-inner {
				position: relative;
				width: 1028px;
				margin: 0 auto;
				
				@media #{$sp} {
					width: auto;
				}
				
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}
	}
	&-post {
		width: 1028px;
		margin: 64px auto 0;
		
		@media #{$sp} {
			width: auto;
			margin: 64px 70px 0;
		}
	}
}

.page-template-default {
	a {
		text-decoration: underline;
	}
	p {
		margin: 28px 0 0 0;
		font-size: 15px;
		line-height: 1.8;
		
		@media #{$sp} {
			margin: 44px 0 0 0;
			font-size: 26px;
		}
		
		&:first-child {
			margin-top: 0;
		}
	}
	h2 {
		margin: 54px 0 0 0;
		color: #3f340e;
		font-size: 36px;
		font-weight: normal;
		line-height: #{(50 / 36)};
		
		@media #{$sp} {
			margin: 80px 0 0 0;
			font-size: 50px;
		}
		
		&:first-child {
			margin-top: 0;
		}
	}
	h3 {
		margin: 82px 0 0 0;
		font-size: 30px;
		font-weight: normal;
		font-style: italic;
		line-height: #{(50 / 30)};
		
		@media #{$sp} {
			margin: 64px 0 0 0;
			font-size: 36px;
		}
		
		&:first-child {
			margin-top: 0;
		}
	}
	h4 {
		margin: 48px 0 0 0;
		font-size: 24px;
		font-weight: bold;
		line-height: #{(40 / 26)};
		
		@media #{$sp} {
			margin: 48px 0 0 0;
			font-size: 28px;
		}
		
		&:first-child {
			margin-top: 0;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}
	.wp-caption {
		margin: 48px 0 0 0;
		
		&.alignnone {
			margin-left: auto;
			margin-right: auto;
			
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
		
		.wp-caption-text {
			margin: 28px 0 0 0;
			text-align: center;
			font-size: 13px;
			font-style: italic;
			line-height: #{(24 / 13)};
			
			@media #{$sp} {
				font-size: 26px;
			}
		}
	}
	img.alignnone {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	blockquote {
		position: relative;
		margin: 132px 0 0 0;
		padding: 36px 36px 56px;
		background-color: #e7e5e5;
		font-family: inherit;
		
		@media #{$sp} {
			margin: 90px 0 0 0;
			padding: 54px 54px 72px;
		}
		
		&:before {
			position: absolute;
			right: 26px;
			top: -24px;
			width: 97px;
			height: 92px;
			background: url(#{$img}contents/detail/blockquote.png) no-repeat;
			content: '';
			
			@media #{$sp} {
				right: 20px;
				top: -30px;
			}
		}
		h3 {
			margin: 10px 0 0 0;
			font-style: normal;
			
			@media #{$sp} {
				position: relative;
				padding: 0 0 36px 0;
				margin: 14px 0 0 0;
				
				&:after {
					position: absolute;
					left: 50%;
					bottom: 0;
					width: 56px;
					height: 1px;
					margin: 0 0 0 -28px;
					background-color: #000;
					content: '';
				}
			}
		}
		p {
		}
	}
	.box01 {
		margin: 88px 0 0 0;
		padding: 40px 64px;
		border: 5px solid #3f340e;
		background: url(#{$img}contents/detail/box01.png) repeat center top;
		
		@media #{$sp} {
			margin: 64px 0 0 0;
			padding: 48px;
			border-width: 8px;
		}
		
		h3 {
			position: relative;
			margin-top: 10px;
			padding-bottom: 22px;
			text-align: center;
			font-style: normal;
			font-weight: bold;
			
			&:after {
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 28px;
				height: 2px;
				margin: 0 0 0 -14px;
				background-color: #000000;
				content: '';
				
				@media #{$sp} {
					width: 64px;
					margin: 0 0 0 -32px;
				}
			}
		}
	}
}