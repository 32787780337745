.contents-youshoku {
	.contents-cat {
		&-header {
			background-image: url(#{$img}contents/youshoku/mv.jpg);
			
			@media #{$sp} {
				background-image: url(#{$img}contents/youshoku/mv-s.jpg);
			}
		}
		&-ttl {
			width: 382px;
			height: 107px;
			background-image: url(#{$img}contents/youshoku/ttl.png);
			
			@media #{$sp} {
				width: 439px;
				height: 188px;
				background-image: url(#{$img}contents/youshoku/ttl-s.png);
			}
		}
		&-about {
			&-ttl {
				width: 919px;
				height: 58px;
				background-image: url(#{$img}contents/youshoku/about-ttl.png);
			
				@media #{$sp} {
					width: 621px;
					height: 172px;
					background-image: url(#{$img}contents/youshoku/about-ttl-s.png);
				}
			}
		}
	}
}