@charset "utf-8";

/*===============================================
 layout
===============================================*/
html {
	height: 100%;
	min-width: #{$pc-center}px;
	overflow-y: scroll;
	
	@media #{$sp} {
		// min-width: 0;
		min-width: 750px;
	}
}

body {
	background-color: #f2f2f2;
}

/*===============================================
 wrap
===============================================*/
#wrap {
	width: 100%;
	overflow: hidden;
}

/*===============================================
 header
===============================================*/
.header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 54px;
	z-index: $z-header;
	background-color: #aa9653;
	
	@media #{$sp} {
		// height: spvw( 80 );
		height: 80px;
	}
	
	&-logo {
		position: relative;
		width: 160px;
		margin: 0 auto;
		
		@media #{$sp} {
			// width: spvw( 160 );
			width: 160px;
		}

		a {
			display: block;
			width: 160px;
			height: 143px;
			background: url(#{$img}common/header-logo.png) no-repeat;
			
			@media #{$sp} {
				// width: spvw( 160 );
				// height: spvw( 141 );
				width: 160px;
				height: 141px;
				background-image: url(#{$img}common/header-logo-s.png);
				background-size: contain;
			}
		}
	}
	&:after {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 228px;
		background: url(#{$img}common/header-over.png) no-repeat center top;
		pointer-events: none;
		content: '';
		
		@media #{$sp} {
			display: none;
		}
	}
	@media #{$sp} {
		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			// height: spvw( 258 );
			height: 258px;
			background: url(#{$img}common/header-over-s.png) no-repeat center top;
			background-size: contain;
			pointer-events: none;
			content: "";
		}
	}
	&-menu {
		position: absolute;
		
	}
}

/*===============================================
 menu
===============================================*/
.menu {
}

/*===============================================
 side
===============================================*/
.side {
}


/*===============================================
 footer
===============================================*/
.footer {
	position: relative;
	padding: 684px 0 48px 0;
	margin: -92px 0 0 0;
	background: url(#{$img}common/footer-bg.png) no-repeat center bottom;
	
	@media #{$sp} {
		// padding: spvw( 630 ) 0 spvw( 100 );
		// padding: 630px 0 100px;
		padding: 630px 0 0;
		margin: -140px 0 0 0;
		background-image: none;
	}
	
	&:after {
		position: absolute;
		left: 50%;
		top: -1px;
		width: 1200px;
		height: 700px;
		margin: 0 0 0 -600px;
		background: url(#{$img}common/footer-over.png) no-repeat;
		content: '';
		
		@media #{$sp} {
			left: 0;
			// top: spvw( -1 );
			width: 100%;
			// height: spvw( 666 );
			top: -1px;
			height: 666px;
			margin: 0;
			background-image: url(#{$img}common/footer-over-s.png);
			background-size: contain;
		}
	}
	&-inner {
		position: relative;
		width: 100%;
		height: 96px;
		padding: 36px 0 0 0;
		box-sizing: border-box;
		background-color: #aa9653;
		
		@media #{$sp} {
			// height: spvw( 182 );
			// padding: spvw( 44 ) 0 0 0;
			height: 182px;
			padding: 44px 0 0 0;
		}
	}
	&-catch {
		width: 596px;
		height: 24px;
		margin: 0 auto;
		background: url(#{$img}common/footer-catch.png) no-repeat;
		
		@media #{$sp} {
			// width: spvw( 559 );
			// height: spvw( 96 );
			width: 559px;
			height: 96px;
			background-image: url(#{$img}common/footer-catch-s.png);
			background-size: contain;
		}
	}
	&-logo {
		position: absolute;
		left: 55px;
		top: 0;
		width: 115px;
		height: 84px;
		background: url(#{$img}common/footer-logo.png) no-repeat;
		
		@media #{$sp} {
			display: none;
		}
	}
	&-copyright {
		position: absolute;
		right: 58px;
		top: 42px;
		width: 66px;
		height: 9px;
		background: url(#{$img}common/footer-copy.png) no-repeat;
		
		@media #{$sp} {
			right: 50%;
			// top: spvw( 312 );
			// width: spvw( 98 );
			// height: spvw( 14 );
			// margin-right: spvw( -49 );
			top: 312px;
			width: 98px;
			height: 14px;
			margin-right: -49px;
			background-image: url(#{$img}common/footer-copy-s.png);
			background-size: contain;
		}
	}
	&-terms {
		position: absolute;
		right: 54px;
		bottom: 135px;
		
		a {
			color: #aa9653;
			font-size: 14px;
			line-height: 1;
		}
	}
	&-sns {
		margin: 32px 0 0 0;
		text-align: center;
		
		@media #{$sp} {
			margin: 48px 0 0 0;
		}

		a {
			display: inline-block;
			margin: 0 16px;
			
			@media #{$sp} {
				margin: 0 32px;
			}

			&:nth-child(1) {
				width: 76px;
				height: 10px;
				background: url(#{$img}common/footer-sns-fb.png) no-repeat;
				
				@media #{$sp} {
					width: 170px;
					height: 25px;
					background-image: url(#{$img}common/footer-sns-fb-s.png);
				}
			}
			&:nth-child(2) {
				width: 64px;
				height: 10px;
				background: url(#{$img}common/footer-sns-tw.png) no-repeat;
				opacity: 0.5;
				
				@media #{$sp} {
					width: 141px;
					height: 25px;
					background-image: url(#{$img}common/footer-sns-tw-s.png);
				}
			}
		}
	}
	&-pagetop {
		width: 37px;
		margin: 68px auto 0;
		
		@media #{$sp} {
			width: 67px;
			margin: 128px auto 0;
		}

		a {
			display: block;
			width: 37px;
			height: 20px;
			background: url(#{$img}common/pagetop.png) no-repeat;
			
			@media #{$sp} {
				width: 67px;
				height: 35px;
				background-image: url(#{$img}common/pagetop-s.png);
			}
		}
	}
}


/*===============================================
 main
===============================================*/
.main {
	padding-top: 54px;
	
	@media #{$sp} {
		// padding-top: spvw( 80 );
		padding-top: 80px;
	}
}

/*===============================================
 gnav
===============================================*/
.gnav {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 99998;
	width: 100%;
	height: 100%;
	background-color: #fff;
	opacity: 0;
	pointer-events: none;
	transition: opacity .4s linear;
	
	&:before {
		position: absolute;
		right: 156px;
		top: 0;
		width: 285px;
		height: 175px;
		background: url(#{$img}common/gnav-over.png) no-repeat center top;
		content: '';
		
		@media #{$sp} {
			// right: spvw( 156 );
			// width: spvw( 285 );
			// height: spvw( 175 );
			right: 156px;
			width: 285px;
			height: 175px;
			background-size: contain;
		}
	}
	
	&-inner {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 1028px;
		transform: translateX( -50% ) translateY( -50% );
		
		@media #{$sp} {
			// width: spvw( 640 );
			width: 640px;
		}
	}
	&-list {
		li {
			margin: 32px 0 0 0;
			
			@media #{$sp} {
				// margin: spvw( 32 ) 0 0 0;
				margin: 32px 0 0 0;
			}
			
			&:first-child {
				margin: 0;
			}
			a {
				display: block;
				background-repeat: no-repeat;
				background-size: contain;
			}
			&:nth-child(1) a {
				width: 98px;
				height: 42px;
				background-image: url(#{$img}common/gnav-top.png);
				
				@media #{$sp} {
					// width: spvw( 98 );
					// height: spvw( 42 );
					width: 98px;
					height: 42px;
				}
			}
			&:nth-child(2) a {
				width: 173px;
				height: 42px;
				background-image: url(#{$img}common/gnav-about.png);
				
				@media #{$sp} {
					// width: spvw( 173 );
					// height: spvw( 42 );
				}
			}
			&:nth-child(3) a {
				width: 233px;
				height: 42px;
				background-image: url(#{$img}common/gnav-contents.png);
				
				@media #{$sp} {
					// width: spvw( 233 );
					// height: spvw( 42 );
				}
			}
			&:nth-child(4) a {
				width: 233px;
				height: 42px;
				background-image: url(#{$img}common/gnav-member.png);
				
				@media #{$sp} {
					// width: spvw( 233 );
					// height: spvw( 42 );
				}
			}
			&:nth-child(5) a {
				width: 172px;
				height: 42px;
				background-image: url(#{$img}common/gnav-events.png);
				
				@media #{$sp} {
					// width: spvw( 421 );
					// height: spvw( 42 );
				}
			}
			&:nth-child(6) a {
				width: 222px;
				height: 42px;
				background-image: url(#{$img}common/gnav-contact.png);
				
				@media #{$sp} {
					// width: spvw( 222 );
					// height: spvw( 42 );
				}
			}
			&:nth-child(7) a {
				width: 421px;
				height: 42px;
				background-image: url(#{$img}common/gnav-membersclub.png);
				
				@media #{$sp} {
					// width: spvw( 421 );
					// height: spvw( 42 );
				}
			}
		}
	}
}
body.gnav-visible {
	.gnav {
		opacity: 1;
		pointer-events: auto;
	}
}
.header-menu {
	position: fixed;
	left: 50px;
	top: 50%;
	z-index: 99999;
	transform: translateY( -50% );
	width: 72px;
	height: 72px;
	background-color:  #aa9653;
	cursor: pointer;
	
	@media #{$sp} {
		left: auto;
		right: 0;
		top: 0;
		transform: none;
		// width: spvw( 120 );
		// height: spvw( 120 );
		width: 120px;
		height: 120px;
	}
	
	.border {
		position: absolute;
		left: 18px;
		right: 18px;
		top: 50%;
		display: block;
		height: 2px;
		
		@media #{$sp} {
			// left: spvw( 32 );
			// right: spvw( 32 );
			left: 32px;
			right: 32px;
			
		}
		
		span {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-color: #1b1b1b;
			transition: transform .2s ease-out, opacity .2s linear;
			
			&:nth-child(1) {
				transform: scaleX( 1 ) translateY( -9px );
				transform-origin: left top;
			}
			&:nth-child(2) {
				transform: scaleX(#{(22 / 28)}) translateY( 0px );
				transform-origin: left top;
			}
			&:nth-child(3) {
				transform: scaleX(#{(16 / 28)}) translateY( 9px );
				transform-origin: left top;
			}
		}
	}
	@media #{$pc} {
		&:hover {
			.border {
				span {
					&:nth-child(1) {
						transform: rotate( 45deg ) scaleX( 0.5 ) translateY( 0px );
						transform-origin: right center;
					}
					&:nth-child(2) {
						transform: scaleX( 1 ) translateY( 0px );
						transform-origin: left top;
					}
					&:nth-child(3) {
						transform: rotate( -45deg ) scaleX( 0.5 ) translateY( 0px );
						transform-origin: right center;
					}
				}
			}
		}
	}
	&.active {
		.border {
			span {
				&:nth-child(1) {
					transform: rotate( 45deg ) scaleX( 1 ) translateY( 0px ) !important;
					transform-origin: center center !important;
				}
				&:nth-child(2) {
					transform: scaleX( 1 ) translateY( 0px ) !important;
					transform-origin: center center !important;
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate( -45deg ) scaleX( 1 ) translateY( 0px ) !important;
					transform-origin: center center !important;
				}
			}
		}
	}
}

/*===============================================
 toromi
===============================================*/
#Liquid {
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-toromi;

	#View {
		position: absolute;
		left: 0;
		top: 0;
	}
}
.mouseDisable {
	pointer-events: none;
}