@charset "utf-8";

/*===============================================
 common
===============================================*/
img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
	text-decoration: none;
	transition: opacity .1s linear;
	cursor: default;

	&[href] {
		cursor: pointer;
		
		&:hover {
			opacity: 0.7;
		}
	}
}

.alt {
	display: none;
}
