.about {
	background-color: #f2f2f2;
	padding: 155px 0 0;

	.page-main-ttl {
		background: url(#{$img}about/about-title.png) no-repeat;
		background-size: contain;
		position: absolute;
		top: -32px;
		left: 50%;
		transform: translateX(-50%);
		height: 67px;
		width: 610px;
		z-index: 10;
	}
	
	.wrap {
		margin: auto;
		padding: 110px 0 0;
		width: 1029px;
	}

	&-mv {
		background-color: #fff;
		margin: auto;
		position: relative;
		height: 685px;
		width: 1373px;

		&-image {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 10;
		}
	}

	&-main-copy {
		background: url(#{$img}about/about-main-copy.png) no-repeat;
		background-size: contain;
		position: absolute;
		bottom: -77px;
		left: 50%;
		transform: translateX(-50%);
		height: 119px;
		width: 430px;
		z-index: 10;
	}

	&-effective {
		background: #eee url(#{$img}about/toromi3-bg.png) center top no-repeat;
		position: relative;
		margin: auto;
		width: 1373px;

		.wrap {
			padding: 0;
		}

		&-lead {
			font-size: 1.125rem;
			letter-spacing: 5px;
			padding: 118px 0 95px;
			text-align: center;
		}

		&-item-inner {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&-item {
			width: 294px;

			p {
				font-size: 0.875rem;
				letter-spacing: 3px;
				line-height: 1.7;
				padding: 15px 0 0;
				text-align: center;
			}
		}

		&-description {
			font-size: 1.125rem;
			letter-spacing: 5px;
			line-height: 2;
			padding: 75px 0 85px;
			text-align: center;
		}

		&-drop {
			background: url(#{$img}about/toromi3-bg-2.png) no-repeat;
			background-size: contain;
			position: absolute;
			top: 224px;
			left: 125px;
			height: 555px;
			width: 1069px;
		}
	}

	&-management-philosophy {
		background-color: #fff;

		&-ttl {
			background: url(#{$img}about/managementphilosophy-title.png) no-repeat;
			background-size: contain;
			margin: auto;
			height: 69px;
			width: 502px;
		}

		&-copy {
			background: url(#{$img}about/managementphilosophy-copy.png) no-repeat;
			background-size: contain;
			margin: 55px 0;
			height: 317px;
			width: 1029px;
		}

		&-lead {
			font-size: 1.125rem;
			letter-spacing: 3px;
			line-height: 2;
			text-align: center;
		}
	}

	&-main-components {
		background-color: #fff;

		.wrap {
			width: 1073px;
		}

		&-ttl {
			background: url(#{$img}about/maincomponents-title.png) no-repeat;
			background-size: contain;
			margin: auto;
			height: 68px;
			width: 569px;
		}

		&-item-inner {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&-item {
			padding: 30px 0 0;
			width: 503px;

			p {
				font-size: 0.875rem;
				letter-spacing: 3px;
				line-height: 1.7;
				padding: 15px 0 0;
			}
		}
	}

	&-company-profile {
		background-color: #fff;

		.wrap {
			width: 575px;
		}

		&-ttl {
			background: url(#{$img}about/company-title.png) no-repeat;
			background-size: contain;
			margin: 0 auto 60px;
			height: 69px;
			width: 339px;
		}

		dl {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			justify-content: flex-start;
		}

		dt {
			line-height: 1.875;
			width: 145px;
		}

		dd {
			line-height: 1.875;
		}
	}

	&-map {
		margin: auto;
		padding: 125px 0 85px;
		height: 514px;
		width: 1071px;
	}
}


/*===============================================
 responsive
===============================================*/
@media #{$sp} {
	.about {
		background-color: #fff;
		padding: 0;

		.page-main-ttl {
			top: auto;
			bottom: 118px;
			height: 67px;
			width: 610px;
		}
	
		.wrap {
			margin: auto;
			padding: 120px 70px 0;
			width: 610px;
		}

		&-mv {
			background: url(#{$img}about/about-main-visual-sp.png) no-repeat;
			background-size: cover;
			margin: auto;
			position: relative;
			width: 100%;
			height: 842px;
		}

		&-main-copy {
			background: url(#{$img}about/about-main-copy-sp.png) no-repeat;
			background-size: contain;
			bottom: -120px;
			width: 610px;
			height: 159px;
		}

		&-effective {
			background: #eee url(#{$img}about/toromi3-bg-sp.png) center top no-repeat;
			background-size: contain;
			width: 100%;

			.wrap {
				padding: 120px 70px 0;
			}

			&-lead {
				font-size: 26px;
				padding: 192px 0 72px;
			}

			&-item {
				margin: auto;
				padding: 80px 0 0;
				width: 100%;
				max-width: 441px;
	
				p {
					font-size: 26px;
					padding: 25px 0 0;
				}

				&:nth-child(1) {
					padding: 0;
				}
			}

			&-description {
				font-size: 30px;
				letter-spacing: 0;
				line-height: 1.6;
				padding: 135px 0 90px;
			}
		}

		&-management-philosophy {
			&-ttl {
				background: url(#{$img}about/managementphilosophy-title-sp.png) no-repeat;
				background-size: contain;
				width: 610px;
				height: 183px;
			}

			&-copy {
				background: url(#{$img}about/managementphilosophy-copy-sp.png) no-repeat;
				background-size: contain;
				width: 610px;
				height: 367px;
				margin: 50px 0;
			}

			&-lead {
				font-size: 26px;
				line-height: 1.85;
			}
		}

		&-main-components {
			.wrap {
				width: 610px;
			}

			&-ttl {
				background: url(#{$img}about/maincomponents-title-sp.png) no-repeat;
				background-size: contain;
				width: 610px;
				height: 143px;
			}

			&-item {
				width: 100%;
				max-width: 610px;
				padding: 50px 0 0;

				p {
					padding: 25px 0 0;
					font-size: 26px;
					letter-spacing: 2.5px;
					line-height: 1.53;
				}
			}
		}

		&-company-profile {
			.wrap {
				width: 610px;
			}

			&-ttl {
				background: url(#{$img}about/company-title-sp.png) no-repeat;
				background-size: contain;
				width: 610px;
				height: 100px;
				margin: 0 auto 65px;
			}

			dt {
				width: 175px;
				font-size: 26px;
			}

			dd {
				font-size: 26px;
			}
		}

		&-map {
			margin: auto;
			width: 100%;
			height: 840px;
			padding: 82px 0 57px;
		}
	}
}