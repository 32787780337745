.contents-koukishin {
	.contents-cat {
		&-header {
			background-image: url(#{$img}contents/koukishin/mv.jpg);
			background-color: #595959;
			
			@media #{$sp} {
				// background-image: url(#{$img}contents/koukishin/mv-s.jpg);
			}
		}
		&-ttl {
			width: 227px;
			height: 62px;
			background-image: url(#{$img}contents/koukishin/ttl.png);
			
			@media #{$sp} {
				width: 341px;
				height: 92px;
				background-image: url(#{$img}contents/koukishin/ttl-s.png);
			}
		}
		&-about {
			&-ttl {
				width: 444px;
				height: 58px;
				background-image: url(#{$img}contents/koukishin/about-ttl.png);
			
				@media #{$sp} {
					width: 566px;
					height: 72px;
					background-image: url(#{$img}contents/koukishin/about-ttl-s.png);
				}
			}
		}
	}
}