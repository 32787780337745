@charset "utf-8";

$img: "../images/";

$sp-base: 750;
$pc-base: 1500;
$pc-center: 1280;

$z-toromi: 9999;
$z-header: 999;
