@import url('https://fonts.googleapis.com/css?family=Sawarabi+Mincho&subset=japanese');

@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 400;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Bold");
	font-weight: bold;
}

$ff-min: 'Sawarabi Mincho', serif;

$fc-default: $color-black;

body {
	color: $fc-default;
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","Yu Gothic", YuGothic, Meiryo, sans-serif;
	font-feature-settings : "palt";
	letter-spacing: 0.05em;
	
	@media #{$sp} {
		letter-spacing: 0.025em;
	}
}
