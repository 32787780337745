.contents {
	padding: 228px 0 0 0;
	
	@media #{$sp} {
		padding: 0;
	}
	
	&-main {
		position: relative;
		max-width: 1373px;
		padding: 92px 0 228px;
		margin: 0 auto;
		background-color: #fff;
		
		@media #{$sp} {
			padding: 116px 70px 160px;
		}
	}
	.page-main {
		&-ttl {
			position: absolute;
			left: 50%;
			top: 0;
			width: 389px;
			height: 68px;
			transform: translateX( -50% ) translateY( -50% );
			background: url(#{$img}contents/contents-ttl.png) no-repeat;
			background-size: contain;
			
			@media #{$sp} {
				position: relative;
				left: 0;
				transform: none;
				margin: 0 auto;
			}
		}
		&-catch {
			text-align: center;
			font-size: 18px;
			
			@media #{$sp} {
				margin: 48px 0 0 0;
				font-size: 26px;
				line-height: #{(40 / 26)};
			}
		}
	}
	.wrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 1072px;
		margin: 68px auto 0;
		
		@media #{$sp} {
			width: 100%;
			margin: 64px 0 0 0;
		}
	}
	&-item {
		width: 500px;
		height: 320px;
		margin: 48px 0 0 48px;
		
		&:nth-child(-n+2) {
			margin-top: 0;
		}
		&:nth-child(2n+1) {
			margin-left: 0;
		}
		
		@media #{$sp} {
			width: 100%;
			height: 390px;
			margin: 28px 0 0 0;
			
			&:nth-child(-n+2) {
				margin-top: 28px;
			}
			&:first-child {
				margin-top: 0;
			}
		}
		
		a {
			position: relative;
			display: block;
			color: #fff;
			text-decoration: none;
				
			&[target="_blank"] {
				&:after {
					position: absolute;
					right: 18px;
					top: 18px;
					display: block;
					width: 16px;
					height: 15px;
					background: url(#{$img}common/ico-blank.png) no-repeat;
					background-size: contain;
					pointer-events: none;
					content: '';
					
					@media #{$sp} {
						right: 36px;
						top: 36px;
						width: 32px;
						height: 30px;
					}
				}
			}
		}
		&-pic {
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-inner {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate( -50%, -50% );
			// width: percentage( 320 / 500 );
			width: 90%;
		}
		&-ttl {
			color: #fff;
			font-size: 25px;
			text-align: center;
			
			@media #{$sp} {
				// font-size: spvw( 35 );
				font-size: 40px;
			}
		}
		&-description {
			margin: 1em 0 0 0;
			text-align: center;
			font-size: 15px;
			line-height: #{(26 / 15)};
			
			@media #{$sp} {
				text-align: center;
				// font-size: spvw( 21 );
				font-size: 26px;
			}
		}
	}
	
	&-cat {
		&-header {
			position: relative;
			width: 100%;
			height: 420px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			
			@media #{$sp} {
				height: 444px;
			}
		}
		&-ttl {
			position: absolute;
			left: 50%;
			top: 134px;
			transform: translateX( -50% );
			background-repeat: no-repeat;
			background-size: contain;
			
			@media #{$sp} {
				top: 178px;
			}
		}
		&-main {
			position: relative;
			max-width: 1375px;
			padding: 90px 0 210px 0;
			margin: -134px auto 0;
			background-color: #fff;
			
			@media #{$sp} {
				padding: 0 0 160px 0;
				margin: 0;
			}
		}
		&-about {
			width: 1028px;
			margin: 54px auto 140px;
			
			@media #{$sp} {
				width: auto;
				margin: 0 0 70px 0;
				padding: 56px 70px;
				background-color: #f2f2f2;
			}
			
			&-ttl {
				margin: 0 auto;
				background-repeat: no-repeat;
				background-size: contain;
			}
			&-inner {
				margin: 48px 0 0 0;
				
				@media #{$sp} {
					margin: 54px 0 0 0;
				}
			}
		}
		&-wrap {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			width: 1028px;
			margin: 0 auto;
			
			@media #{$sp} {
				flex-direction: column;
				flex-wrap: nowrap;
				width: auto;
				margin: 0 70px;
			}
		}
		&-item {
			width: 320px;
			margin: 56px 0 0 34px;
			
			&:nth-child(-n+3) {
				margin-top: 0;
			}
			&:nth-child(3n+1) {
				margin-left: 0;
			}
			
			@media #{$sp} {
				width: auto;
				margin: 56px 0 0 0;
				
				&:nth-child(-n+3) {
					margin-top: 56px;
				}
				&:first-child {
					margin-top: 0;
				}
			}
			
			a {
				display: block;
				color: #000;
			}
			&-pic {
				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&-header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 25px 0 0 0;
				
				@media #{$sp} {
					margin: 32px 0 0 0;
				}
			}
			&-cats {
				font-size: 0;
				
				span {
					display: inline-block;
					
					&.cat-event {
						width: 75px;
						height: 17px;
						background: url(#{$img}contents/cat-event.png) no-repeat;
						
						@media #{$sp} {
							width: 144px;
							height: 33px;
							background-image: url(#{$img}contents/cat-event-s.png);
						}
					}
					&.cat-news {
						width: 64px;
						height: 17px;
						background: url(#{$img}contents/cat-news.png) no-repeat;
						
						@media #{$sp} {
							width: 122px;
							height: 33px;
							background-image: url(#{$img}contents/cat-news-s.png);
						}
					}
				}
			}
			&-pubdate {
				font-size: 16px;
				font-style: italic;
				line-height: 1;
				
				@media #{$sp} {
					font-size: 20px;
				}
			}
			&-body {
				margin: 20px 0 0 0;
				
				@media #{$sp} {
					margin: 36px 0 0 0;
				}
			}
			&-ttl {
				font-size: 14px;
				line-height: #{(24 / 14)};
				
				@media #{$sp} {
					font-size: 26px;
					line-height: #{(40 / 26)};
				}
			}
		}
	}
}