.contact {
	.page-main-ttl {
		background: url(#{$img}contact/contact-title.png) no-repeat;
		background-size: contain;
		position: absolute;
		top: -32px;
		left: 50%;
		transform: translateX(-50%);
		height: 67px;
		width: 610px;
		z-index: 10;
	}

	&-main {
		background: url(#{$img}contact/contact-bg.jpg) repeat;
		margin: 229px auto 0;
		padding: 90px 0 55px;
		position: relative;
		width: 1373px;
	}

	&-copy {
		font-size: 1.125rem;
		letter-spacing: 5px;
		line-height: 2;
		padding: 0 0 70px;
		text-align: center;
	}

	&-form {
		margin: auto;
		width: 670px;

		dl {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			justify-content: flex-start;
			padding: 0 0 20px;

			&:nth-last-of-type(1) {
				padding: 0 0 85px;
			}
		}

		dt {
			line-height: 3.125;
			width: 235px;
		}

		dd {
			width: 435px;
		}
		
		.error {
			margin: 0.5em 0 0 0;
			color: #f00;
		}

		input[type="text"], input[type="tel"], input[type="submit"], textarea {
			box-sizing: border-box;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		input[type="text"], input[type="tel"], textarea {
			border: 1px solid #1b1b1b;
			padding: 0 15px;
			height: 50px;
			width: 100%;
		}

		textarea {
			padding: 15px;
			height: 260px;
		}

		label {
			display: block;
			padding: 10px 0;
		}

		input[type="submit"] {
			display: block;
			margin: auto;
		}

		input[type="submit"]:hover {
			cursor: pointer;
			opacity: 0.7;
		}

		.contact-submit-btn {
			background: url(#{$img}contact/contact-send.png) repeat;
			background-size: contain;
			border: 0px;
			height: 143px;
			width: 205px;
		}

		.reqire {
			color: #ec0000;
			display: inline-block;
			padding: 0 0 0 10px;
		}
	}
}


/*===============================================
 responsive
===============================================*/
@media #{$sp} {
	.contact {
		.page-main-ttl {
			top: 142px;
			width: 610px;
			height: 67px;
		}

		&-main {
			background: #fff none;
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}

		&-copy {
			padding: 240px 0 60px;
			font-size: 26px;
			letter-spacing: 2px;
			line-height: 2;
		}

		&-form {
			background: url(#{$img}contact/contact-bg.jpg) center top repeat;
			width: 610px;
			padding: 65px 70px 55px;
			margin: auto;

			dl {
				padding: 0 0 35px;

				&:nth-last-of-type(1) {
					padding: 0 0 73px;
				}
			}

			dt {
				font-size: 24px;
				width: 100%;
			}

			dd {
				font-size: 24px;
				width: 100%;
			}

			input[type="text"], input[type="tel"], textarea {
				padding: 0 22px;
				height: 75px;
				width: 100%;
				font-size: 24px;
			}

			textarea {
				padding: 22px;
				height: 390px;
			}

			label {
				padding: 20px 0;
			}

			.contact-submit-btn {
				width: 245px;
				height: 171px;
			}

			.reqire {
				padding: 0 0 0 22px;
			}
		}
	}
}